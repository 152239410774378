import { SelectItem } from './models/index';

export const yesNo: SelectItem[] = [
	{
		value: false,
		label: "Nee"
	},
	{
		value: true,
		label: "Ja"
	}
];

export const yesNoWithEmpty: SelectItem[] = [
	{
		value: null,
		label: "Geen"
	},
	{
		value: false,
		label: "Nee"
	},
	{
		value: true,
		label: "Ja"
	}
];

export const languages: SelectItem[] = [
	{
		value: "NL",
		label: "Nederlands"
	},
	{
		value: "EN",
		label: "Engels"
	},
	{
		value: "DE",
		label: "Duits"
	},
	{
		value: "FR",
		label: "Frans"
	}
];

export const genders: SelectItem[] = [
	{
		value: "M",
		label: "Man"
	},
	{
		value: "F",
		label: "Vrouw"
	},
	{
		value: "O",
		label: "Onbekend"
	}
]

export const statuses: SelectItem[] = [
	{
		value: 1,
		label: "Actief"
	},
	{
		value: 2,
		label: "Geblokkeerd"
	},
	{
		value: 3,
		label: "Pilot"
	},
	{
		value: 4,
		label: "Referentie"
	},
	{
		value: 5,
		label: "Passief"
	}
]

export const functionDescriptions: String[] = [
	"Sommelier",
	"Financieel verantwoordelijke",
	"Directeur",
	"Inkoop"
]
